import { addons } from '@storybook/addons'
import { create } from '@storybook/theming'

import logo from '../public/images/public-logo-1000.png'

const theme = create({
  base: 'light',

  brandTitle: 'Public Web',
  brandUrl: 'https://public.com',
  brandImage: logo,
})

addons.setConfig({ theme })
